@import "./../../../Sass/mixins.scss";
@import "./../../../Sass/Variables.scss";

.team-body {
	position: relative;

	&::before {
		content: "";
		background-color: $main-color;
		width: 100%;
		height: 50%;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.swiper-container {
		height: 530px;

		@media (max-width: $maxXSmallScreens) {
			height: 430px;
		}

		.swiper-button-prev,
		.swiper-button-next {
			position: absolute;
			color: #fff;
			bottom: 20px;
			top: auto;

			&::after {
				font-size: 20px;
				font-weight: bold;
			}
		}

		.swiper-button-prev {
			left: 50%;
			transform: translateX(-150%);
		}

		.swiper-button-next {
			right: 50%;
			transform: translateX(150%);
		}

		.swiper-wrapper {
			position: relative;
			top: 50px;
		}
	}

	.team-member {
		position: relative;
		background-color: $upper-background-color;
		display: inline-block;
		border-radius: 45px;
		text-align: center;
		box-shadow: 0 3px 6px rgba(#000000, 0.1);
		width: 100%;
		padding: 40px 20px;
		overflow: hidden;
		transition: all 0.2s linear;

		@media (max-width: $maxXSmallScreens) {
			padding: 20px 10px;
			border-radius: 30px;
		}

		.member-link {
			@include overlay-link;
		}

		.member-image {
			object-fit: contain;
			width: 170px;
			height: 180px;
			border-radius: 50%;
			margin: auto;
			overflow: hidden;
			position: relative;
			margin-bottom: 30px;

			@media (max-width: $maxXSmallScreens) {
				width: 120px;
				height: 130px;
			}

			img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				user-select: none;

				@media (max-width: $maxXSmallScreens) {
					transform: translate(-50%, -50%) scale(0.7);
				}
			}
		}

		.member-name {
			font-weight: 600;
			font-size: 1.5rem;
			color: $fourth-color;
			margin-bottom: 5px;
			transition: all 0.2s linear;

			@media (max-width: $maxXSmallScreens) {
				font-size: 1.2rem;
			}
		}

		.member-role {
			color: $third-color;
			margin-bottom: 20px;
			transition: all 0.2s linear;

			@media (max-width: $maxXSmallScreens) {
				font-size: 0.8rem;
			}
		}

		.member-social-media {
			display: grid;
			grid-template-columns: repeat(3, 40px);
			justify-content: center;
			gap: 5px;

			@media (max-width: $maxXSmallScreens) {
				grid-template-columns: repeat(3, 30px);
			}

			.social-link {
				@include reset-link;
				position: relative;

				.icon {
					font-size: 1.7rem;
					color: $fourth-color;
					transition: all 0.2s linear;

					@media (max-width: $maxXSmallScreens) {
						font-size: 1.3rem;
					}
				}
			}
		}

		&:hover {
			background-color: $main-color;
			transform: translateY(-20px);
			box-shadow: 0 8px 15px rgba(#000000, 0.2);

			.member-name,
			.member-role,
			.member-social-media .social-link .icon {
				color: #fff;
			}
		}
	}
}
