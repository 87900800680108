@import "./../../Sass/Variables.scss";

.team {
	background-color: $background-color;
	padding-top: 150px;
	position: relative;
	min-height: 400px;

	@media (max-width: $maxXSmallScreens) {
		padding-top: 120px;
	}
}
