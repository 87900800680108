@import "../../Sass/Variables";

.services {
	position: relative;
	background-color: $background-color;
	background-size: cover;
	background-position: center left;
	text-align: center;
	padding-top: 150px;
	padding-bottom: 50px;
	min-height: 400px;

	@media (max-width: $maxSmallScreens) {
		padding-top: 110px;
		background-position: center center;
	}
}
