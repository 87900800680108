@import "./../../../Sass/variables";
@import "./../../../Sass/mixins";

.about-body {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	align-items: center;
	justify-content: space-between;
	gap: 50px;

	@media (max-width: $maxSmallScreens) {
		grid-template-columns: 1fr;
		gap: 30px;
	}

	.about-image {
		img {
			width: 100%;
			user-select: none;
		}
	}

	.about-desc {
		transition: opacity 0.3s linear;

		.desc-title {
			@include second-header;
			font-size: 1.5rem;
			margin-bottom: 25px;

			@media (max-width: $maxMediumScreens) {
				margin-bottom: 20px;
			}
		}

		.desc-paragraph {
			@include body-text;
			margin-bottom: 16px;
			font-size: 1.2rem;

			@media (max-width: $maxMediumScreens) {
				font-size: 1rem;
			}
		}

		.desc-btn {
			@include reset-btn;
			color: $main-color;
			font-weight: bold;
			font-size: 1.1rem;

			@media (max-width: $maxMediumScreens) {
				font-size: 1rem;
			}
		}
	}
}
