// Start Main Colors Using Sass Variables [ COnvert Css Variables To Sass Variables For Easy Syntax ]

$main-color: var(--main-color);
$second-color: var(--second-color);
$third-color: var(--third-color);
$fourth-color: var(--fourth-color);
$white-color: var(--white-color);

$background-color: var(--background-color);
$upper-background-color: var(--upper-background-color);

$box-shadow-third-color: var(--box-shadow-third-color);
$box-shadow-main-color: var(--box-shadow-main-color);
$box-shadow-white-color: var(--box-shadow-white-color);

// End Main Colors Using Sass Variables [ COnvert Css Variables To Sass Variables For Easy Syntax ]
