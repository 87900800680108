@import "./../../../Sass/Variables.scss";
@import "./../../../Sass/mixins.scss";

.footer-header {
	margin-bottom: 120px;

	@media (max-width: $maxSmallScreens) {
		margin-bottom: 80px;
	}

	.footer-title {
		text-align: center;
		margin-bottom: 30px;
		color: #fff;
		font-size: 1.7rem;

		@media (max-width: $maxXSmallScreens) {
			font-size: 1.5rem;
			line-height: 1.7;
		}
	}

	.newsletter-form {
		@include newsletter-form;
	}
}
