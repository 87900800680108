@import "./../../Sass/variables";
@import "./../../Sass/mixins";

.main-header {
	background-color: $background-color;
	position: relative;
	min-height: 600px;

	// Header Inner Used For Grid
	.header-inner {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 50px;
		align-items: center;
		min-height: 700px;

		@media (min-width: $minLargeScreens) {
			height: 100vh;
			max-height: 700px;
		}

		@media (max-width: $maxMediumScreens) {
			height: 100vh;
		}

		@media (max-width: $maxSmallScreens) {
			grid-template-columns: 1fr;
			height: auto;
			padding-top: 120px;
			padding-bottom: 70px;
		}

		.header-desc {
			@media (max-width: $maxSmallScreens) {
				text-align: center;
			}

			.header-title {
				color: $third-color;
				font-size: 3.5rem;
				font-weight: normal;

				@media (max-width: $maxLargeScreens) {
					font-size: 2.7rem;
				}

				@media (max-width: $maxXSmallScreens) {
					font-size: 2.3rem;
				}

				span {
					color: $main-color;
					font-weight: bold;
				}
			}

			.header-paragraph {
				@include body-text;

				font-size: 1.2rem;
				line-height: 1.6;
				margin-top: 40px;
				margin-bottom: 30px;

				@media (max-width: $maxLargeScreens) {
					font-size: 1.1rem;
				}
			}

			.header-btn {
				@include main-btn(1rem, 15px 40px, none);

				&:hover {
					transform: translateX(7px);
				}
			}
		}

		.header-image {
			img {
				display: block;
				width: 100%;
				user-select: none;

				@media (max-width: $maxSmallScreens) {
					width: 90%;
					margin: auto;
				}
			}
		}
	}
}
