@import "./../../Sass/variables.scss";

.loading-dual-ring {
	display: block;
	margin: auto;
	width: 80px;
	height: 80px;
	background-color: transparent;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	&:after {
		content: " ";
		display: block;
		width: 64px;
		height: 64px;
		margin: 8px;
		border-radius: 50%;
		border-width: 6px;
		border-style: solid;
		border-color: $main-color transparent $main-color transparent;
		animation: loading-dual-ring 1.2s linear infinite;
	}
}

@keyframes loading-dual-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
