@import "./../../../Sass/variables";
@import "./../../../Sass/mixins";

.portfolio-nav {
	position: relative;
	margin-bottom: 60px;

	@media (max-width: $maxSmallScreens) {
		margin-bottom: 40px;
	}

	.portfolio-list {
		@include reset-list;
		text-align: center;

		.portfolio-item {
			display: inline-block;
			padding-left: 30px;
			padding-right: 30px;
			cursor: pointer;
			font-weight: 600;
			font-size: 1.25rem;
			transition: color 0.3s linear;

			&:not(.active) {
				color: $third-color;
			}

			&.active {
				color: $white-color;
			}

			@media (max-width: $maxSmallScreens) {
				padding-left: 17px;
				padding-right: 17px;
				font-size: 1.1rem;
			}

			@media (max-width: $maxXSmallScreens) {
				width: 100%;
				padding: 10px;
				font-size: 1.1rem;
			}
		}
	}

	.list-line {
		position: absolute;
		bottom: -15px;
		height: 3px;
		background-color: $white-color;
		transition: all 0.3s linear;

		@media (max-width: $maxSmallScreens) {
			display: none;
		}
	}
}
